import { createRouter, createWebHistory } from 'vue-router'
import { canActivate } from '../services/auth-guard.service'
import Main from '../views/Main.vue'
import Callback from '../views/Callback.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main,
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/init',
    name: 'Init',
    meta: { skipMerchantCheck: true },
    component: () => import('../views/Init.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate(to))
    }
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/Products/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/products/new',
    name: 'New Product',
    component: () => import('../views/Products/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/products/edit/:id',
    name: 'Update Product',
    component: () => import('../views/Products/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import('../views/Categories/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/Categories/new',
    name: 'New Category',
    component: () => import('../views/Categories/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories/edit/:id',
    name: 'Update Category',
    component: () => import('../views/Categories/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories/:id/propertygroups',
    name: 'Property Groups',
    component: () => import('../views/Categories/PropertyGroups/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories/:id/propertygroups/new',
    name: 'New Property Group',
    component: () => import('../views/Categories/PropertyGroups/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories/:id/propertygroups/:propertyGroupId',
    name: 'Update Property Group',
    component: () => import('../views/Categories/PropertyGroups/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories/:id/propertygroups/:propertyGroupId/properties/new',
    name: 'New Property',
    component: () => import('../views/Categories/PropertyGroups/Properties/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories/:id/propertygroups/:propertyGroupId/properties',
    name: 'Properties',
    component: () => import('../views/Categories/PropertyGroups/Properties/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/categories/:id/propertygroups/:propertyGroupId/properties/:propertyId',
    name: 'Update Property',
    component: () => import('../views/Categories/PropertyGroups/Properties/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/items',
    name: 'Items',
    component: () => import('../views/Items/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/items/new',
    name: 'New Item',
    component: () => import('../views/Items/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/items/edit/:id',
    name: 'Update Item',
    component: () => import('../views/Items/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/productmodels',
    name: 'Product Models',
    component: () => import('../views/ProductModels/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/productmodels/new',
    name: 'New Product Model',
    component: () => import('../views/ProductModels/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/productmodels/edit/:id',
    name: 'Update Product Model',
    component: () => import('../views/ProductModels/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/variants',
    name: 'Variants',
    component: () => import('../views/Variants/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/variants/new',
    name: 'New Variant',
    component: () => import('../views/Variants/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/variants/edit/:id',
    name: 'Update Variant',
    component: () => import('../views/Variants/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings/Main.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    },
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    component: () => import('../views/Webhooks/Manage.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/webhooks/new',
    name: 'New Webhook',
    component: () => import('../views/Webhooks/New.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
  {
    path: '/webhooks/edit/:id',
    name: 'Update Webhook',
    component: () => import('../views/Webhooks/Edit.vue'),
    beforeEnter: (to, from, next) => {
      next(canActivate())
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
