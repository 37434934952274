<template>
  <Loader />
</template>

<script>
import { webAuth } from "../services/auth0.service";
import Loader from "@/components/Loader.vue";

export default {
  name: "callback",
  async mounted() {
    if (window.location.hash) {
      var token = window.location.hash.split("=")[1].split("&")[0];
      localStorage.setItem("token", token);

      // Save user info
      webAuth.client.userInfo(token, function (err, user) {
        // Now you have the user's information
        localStorage.setItem("nick", user.nickname);

        window.location.href = "/";
      });
    }
  },
};
</script>
