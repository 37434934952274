<template>
  <div class="app-content pt-3 p-md-3 p-lg-4">
    <div class="container-xl pt-5">
      <h1 class="app-page-title">Overview</h1>

      <div class="row g-4 mb-4">
        <div class="col-12 col-lg-4">
          <div class="
              app-card app-card-basic
              d-flex
              flex-column
              align-items-start
              shadow-sm
            ">
            <div class="app-card-header p-3 border-bottom-0">
              <div class="row align-items-center gx-3">
                <div class="col-auto">
                  <div class="app-icon-holder">
                    <i class="bi bi-bookmarks"></i>
                  </div>
                  <!--//icon-holder-->
                </div>
                <!--//col-->
                <div class="col-auto">
                  <h4 class="app-card-title">Categories</h4>
                </div>
                <!--//col-->
              </div>
              <!--//row-->
            </div>
            <!--//app-card-header-->
            <div class="app-card-body px-4">
              <div class="intro">
                Categories are the catalogs for your items, products, product
                models and variants.
              </div>
            </div>
            <!--//app-card-body-->
            <div class="app-card-footer p-4 mt-auto">
              <div class="btn-group">
                <router-link to="/categories/new" class="btn app-btn-secondary">Create New</router-link>
                <router-link to="/categories" class="btn app-btn-secondary">Manage</router-link>
              </div>
            </div>
            <!--//app-card-footer-->
          </div>
          <!--//app-card-->
        </div>
        <!--//col-->
        <div class="col-12 col-lg-4">
          <div class="
              app-card app-card-basic
              d-flex
              flex-column
              align-items-start
              shadow-sm
            ">
            <div class="app-card-header p-3 border-bottom-0">
              <div class="row align-items-center gx-3">
                <div class="col-auto">
                  <div class="app-icon-holder">
                    <i class="bi bi-boxes"></i>
                  </div>
                  <!--//icon-holder-->
                </div>
                <!--//col-->
                <div class="col-auto">
                  <h4 class="app-card-title">Items</h4>
                </div>
                <!--//col-->
              </div>
              <!--//row-->
            </div>
            <!--//app-card-header-->
            <div class="app-card-body px-4">
              <div class="intro">
                Items are goods that can be included as components of products.
              </div>
            </div>
            <!--//app-card-body-->
            <div class="app-card-footer p-4 mt-auto">
              <div class="btn-group">
                <router-link to="/items/new" class="btn app-btn-secondary">Create New</router-link>
                <router-link to="/items" class="btn app-btn-secondary">Manage</router-link>
              </div>
            </div>
            <!--//app-card-footer-->
          </div>
          <!--//app-card-->
        </div>
        <!--//col-->

        <div class="col-12 col-lg-4">
          <div class="
              app-card app-card-basic
              d-flex
              flex-column
              align-items-start
              shadow-sm
            ">
            <div class="app-card-header p-3 border-bottom-0">
              <div class="row align-items-center gx-3">
                <div class="col-auto">
                  <div class="app-icon-holder">
                    <i class="bi bi-handbag"></i>
                  </div>
                  <!--//icon-holder-->
                </div>
                <!--//col-->
                <div class="col-auto">
                  <h4 class="app-card-title">Products</h4>
                </div>
                <!--//col-->
              </div>
              <!--//row-->
            </div>
            <!--//app-card-header-->
            <div class="app-card-body px-4">
              <div class="intro">
                Products are tangible or intangible entities that are manufactured, created, or offered for sale in the marketplace.
              </div>
            </div>
            <!--//app-card-body-->
            <div class="app-card-footer p-4 mt-auto">
              <div class="btn-group">
                <router-link to="/products/new" class="btn app-btn-secondary">Create New</router-link>
                <router-link to="/products" class="btn app-btn-secondary">Manage</router-link>
              </div>
            </div>
            <!--//app-card-footer-->
          </div>
          <!--//app-card-->
        </div>
        <!--//col-->
        <div class="col-12 col-lg-4">
          <div class="
              app-card app-card-basic
              d-flex
              flex-column
              align-items-start
              shadow-sm
            ">
            <div class="app-card-header p-3 border-bottom-0">
              <div class="row align-items-center gx-3">
                <div class="col-auto">
                  <div class="app-icon-holder">
                    <i class="bi bi-node-plus"></i>
                  </div>
                  <!--//icon-holder-->
                </div>
                <!--//col-->
                <div class="col-auto">
                  <h4 class="app-card-title">Product Models</h4>
                </div>
                <!--//col-->
              </div>
              <!--//row-->
            </div>
            <!--//app-card-header-->
            <div class="app-card-body px-4">
              <div class="intro">
                Product Models are used to manage products with variants.
              </div>
            </div>
            <!--//app-card-body-->
            <div class="app-card-footer p-4 mt-auto">
              <div class="btn-group">
                <router-link to="/productmodels/new" class="btn app-btn-secondary">Create New</router-link>
                <router-link to="/productmodels" class="btn app-btn-secondary">Manage</router-link>
              </div>
            </div>
            <!--//app-card-footer-->
          </div>
          <!--//app-card-->
        </div>
        <!--//col-->
        <div class="col-12 col-lg-4">
          <div class="
              app-card app-card-basic
              d-flex
              flex-column
              align-items-start
              shadow-sm
            ">
            <div class="app-card-header p-3 border-bottom-0">
              <div class="row align-items-center gx-3">
                <div class="col-auto">
                  <div class="app-icon-holder">
                    <i class="bi bi-diagram-2"></i>
                  </div>
                  <!--//icon-holder-->
                </div>
                <!--//col-->
                <div class="col-auto">
                  <h4 class="app-card-title">Variants</h4>
                </div>
                <!--//col-->
              </div>
              <!--//row-->
            </div>
            <!--//app-card-header-->
            <div class="app-card-body px-4">
              <div class="intro">
                Variants are used to identify products that differ in trivial ways from similar
                product model.
              </div>
            </div>
            <!--//app-card-body-->
            <div class="app-card-footer p-4 mt-auto">
              <div class="btn-group">
                <router-link to="/variants/new" class="btn app-btn-secondary">Create New</router-link>
                <router-link to="/variants" class="btn app-btn-secondary">Manage</router-link>
              </div>
            </div>
            <!--//app-card-footer-->
          </div>
          <!--//app-card-->
        </div>
        <!--//col-->
      </div>
      <!--//row-->
    </div>
    <!--//container-fluid-->
  </div>
  <!--//app-content-->
</template>

<script>
import * as navbar from "../services/navbar.service";

export default {
  data: () => ({
    merchant: "...",
    nick: "...",
    apiDocLink: process.env.VUE_APP_API_URL + "/swagger",
    merchantId: localStorage.getItem("merchantId"),
  }),
  mounted() {
    navbar.init(this.$route);
  },

  methods: {
    getNickName() {
      return localStorage.getItem("nick");
    },

    getMerchantName() {
      return localStorage.getItem("merchant");
    },
  },
};
</script>