<template>
  <Navbar v-if="isMerchantValidated()" />
  <div class="app-wrapper">
    <router-view />
    <Footer />
  </div>
  <Loader v-show="loading" />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Loader from "@/components/Loader.vue";
import { emitter } from '@/services/emitter.service';

export default {
  components: {
    Navbar,
    Footer,
    Loader
  },
  data() {
    return { loading: false };
  },
  created() {
    emitter.on("showLoader", (e) => {
      this.loading = e;
    });
  },
  methods: {
    isMerchantValidated() {
      return localStorage.getItem("merchant") != undefined;
    },
  },
};
</script>

<style>
:root {
  --primary-color: #15a362;
  --highlight-color: #E9F5F8;
}

body {
  background: #f5f6fe;
}

a {
  cursor: pointer;
}

i.bi {
  vertical-align: middle;
}

span.nav-icon > i.bi {
  font-size: 18px;
}

div.app-icon-holder > i.bi {
  font-size: 20px;
}

tr td:first-child {
  width: 20px;
}

.btn-danger,
.btn-danger:hover {
  color: white;
  background-color: red;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: silver !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: silver !important;
}

button.nav-link.active {
  background-color: #15a362 !important;
  color: white;
}

button.nav-link,
button.nav-link:hover {
  color: #15a362;
}

@media (max-width: 767px) {
  .table-responsive .dropdown-menu {
    transform: translate3d(0px, 3px, 0px) !important;
    position: relative !important;
  }
}
@media (min-width: 768px) {
  .table-responsive {
    overflow: inherit;
  }
}

.app-btn-outline-primary {
    background: #fff;
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
}

.app-btn-outline-primary:hover {
    background: var(--primary-color);
    color: white;
    border: 1px solid var(--primary-color);
}

.app-btn-outline-danger {
    background: #fff;
    color: crimson;
    border: 1px solid crimson
}

.app-btn-outline-danger:hover {
    background: crimson;
    color: white;
    border: 1px solid crimson
}
</style>