import jwt_decode from "jwt-decode";
import { RouteLocationNormalized } from "vue-router";
import { webAuth } from "../services/auth0.service";

const canActivate = (to) => {
    if (localStorage.getItem('token') != undefined) {

        if (to?.meta?.skipMerchantCheck) {
            return true;
        }
        else {
            // Checks if client selected it`s respective merchant
            if (localStorage.getItem('merchant') != undefined) {
                return true;
            }
            else {
                // Redirects to merchant selector
                if (window.location.href != window.location.origin + "/init") {
                    window.location.href = "init";
                }
                
                return true;
            }
        }
    }

    // Navigate to login page
    localStorage.clear();
    webAuth.authorize({ redirect_uri: window.location.origin + "/callback" });
    return false;
};

export {
    canActivate
}